import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './SmallModal.module.scss';
import Icon from 'icons/icon';
import { Button } from 'sub-components';

const SmallModal = ({ setDisplayModal, onCloseAction, children, hideCloseBtn, hideOkButton}) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setDisplayModal(false); // Close the modal after the animation
            if (onCloseAction) {
                onCloseAction(); // Execute the action passed from the parent
            }
        }, 100); // Match this duration to your CSS animation
    };

    return ReactDOM.createPortal(
        <div className={classes.overlay}>
            <div className={`${isClosing ? classes.closingAnimation : classes.portalAnimation}`}>
                {!hideCloseBtn && (
                    <button className={classes.closeButton} onClick={handleClose}>
                        <Icon color="gray" size={15} icon="cross" />
                    </button>
                )}
                <div className={classes.childrenContainer}>
                {children}
                {!hideOkButton &&
                    <Button className="btn btn-primary" onClick={handleClose}>Ok</Button>
                }
                </div>
            </div>
        </div>,
        document.getElementById('modal-root') // Ensure this exists in your HTML
    );
};

export default SmallModal;
