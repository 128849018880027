import React, { useEffect, useState } from 'react';
import { PageHeader } from 'components';
import { Layout } from 'layout';
import classes from './PrivacyPolicy.module.scss'

const PrivacyPolicyPage = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch('/privacyPolicy.html') // Assuming you moved the HTML file to the public folder
      .then(response => response.text())
      .then(data => setHtmlContent(data))
      .catch(error => console.error('Error loading the privacy policy:', error));
  }, []);

  return (
    <Layout tabHeader="Privacy Policy">
      <div className='colored-background'>
        <PageHeader header={"Privacy Policy"} />
      </div>
      <div className={classes.privacyPolicy} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Layout>
  );
};

export default PrivacyPolicyPage;
