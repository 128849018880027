import React, { useState, useEffect, useRef} from 'react';
import { Layout } from 'layout';
import classes from './UserProfileVideo.module.scss'
import ReactPlayer from 'react-player';
import Play from "@untitled-ui/icons-react/build/cjs/Play";
import PauseCircle from "@untitled-ui/icons-react/build/cjs/PauseCircle";
import { ClimbingBoxLoader } from 'react-spinners';


const UserProfileVideo = (props) => {
	const [isVideoAvailable, setIsVideoAvailable] = useState(props.pitchVideo != null); // Check if a video URL is provided
    const [playing, setPlaying] = useState(props.isPlaying);
    const [isLoading, setIsLoading] = useState(true); // To track loading state of the video
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1023);
    const playerRef = useRef(null); // Ref for ReactPlayer

    const handleClick = () => {
        setPlaying(!playing);  // Toggle play/pause on click
    };

    useEffect(() => {
        playerRef?.current?.seekTo(0);
        setPlaying(props.isPlaying);
        console.log(props.isPlaying);
    }, [props.isPlaying]);

    const handleVideoReady = () => {
        setIsLoading(false); // Video is ready, stop showing the spinner
    };

    const handleBuffer = () => {
        setIsLoading(true); // Video is buffering, show the spinner
    };

    const handleBufferEnd = () => {
        setIsLoading(false); // Video finished buffering
    };

	return (
        <div className={classes.videoContainer}>
            {isVideoAvailable ? (
                <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
                    {isLoading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2,
                            }}
                        >
                            <div className={classes.loadingIcon}>
                                <ClimbingBoxLoader size={17} color={'#2E4C4D'}/>
                            </div>
                        </div>
                    )}
                    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                        <ReactPlayer
                            ref={playerRef} // Attach the ref
                            url={props.pitchVideo}
                            width="100%"
                            height="auto"
                            playing={playing}
                            muted={false}
                            controls={false}
                            playsinline={true}
                            onReady={handleVideoReady}
                            onBuffer={handleBuffer}
                            onBufferEnd={handleBufferEnd}
                        />
                    </div>
                    {/* Custom icon to indicate play/pause, positioned absolutely over the video */}
                    {!playing && !isLoading && (
                        <Play
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                pointerEvents: 'none',
                                height: '45px',
                                width: '45px',
                            }}
                        />
                    )}
                </div>
            ) : props.pitchVideo === "" && (
                <div className={classes.noVidMessage}>
                    <p>They don't have a pitch yet so tell them to post one!</p>
                </div>
            )}
        </div>
	);
};

export default UserProfileVideo;
