import React, {useState, useEffect, useContext, useRef} from 'react';
import Select from 'react-select';
import { updateUserMetadata, changeUserProfileAvatar, getUserProfile, updateUserProfile, getDefaultUserGoals, getDefaultUserTitles, getDefaultUserSkills, getDefaultUserExpertises, getDefaultUserStartUpPast, getDefaultUserInterests, processUserProfileData, getTitleAndGoal, updateProfileTags, getDefaultUserTimeCommit } from 'lib/user';

const SkillsExpertiseInterests = () => {

  const [profile, setProfile] = useState({});

  const defaultSkills = getDefaultUserSkills();
  const defaultExpertises = getDefaultUserExpertises();
  const defaultInterests = getDefaultUserInterests();

  const [tags, setTags] = useState(profile.tags || []);

    /**
       * The `handleTagAdd` function updates profile tags with new tag data based on the selected option.
       * @param selectedOption - The `selectedOption` parameter is an object that represents the option
       * that has been selected by the user. It typically contains properties such as `label` for the
       * display name of the option, `value` for the unique identifier of the option, and `category` for
       * the category to which the option
       * @param type - The `type` parameter in the `handleTagAdd` function is used to specify the type of
       * action being performed when adding a tag. It could be used to differentiate between different
       * types of tag additions or to determine how the tag should be processed within the function.
       */
  const handleTagAdd = (selectedOption, type) => {
    if (selectedOption) {
        const newData = {
            display_name: selectedOption.label,
            id: selectedOption.value,
            category: selectedOption.category
        };
        
        // Update the profile tags with the new tag data
        const updatedTags = updateProfileTags(profile, newData, selectedOption.category, type);
        setProfile({ ...profile, tags: updatedTags });
    }
  };

  /**
       * The function `handleDeleteTagClick` is used to remove a specific tag from a nested data
       * structure based on its id and type.
       * @param event - The `event` parameter is the event object that is passed to the event handler
       * function. It contains information about the event that occurred, such as the type of event,
       * target element, and any additional data related to the event. In this case, it is used to
       * prevent the event from propagating
       * @param id - The `id` parameter in the `handleDeleteTagClick` function represents the unique
       * identifier of the tag that needs to be deleted.
       * @param type - The `type` parameter in the `handleDeleteTagClick` function represents the type of
       * tag that is being deleted. It is used to identify the specific tag within the `profile.tags`
       * array that needs to be updated or removed.
       */
  const handleDeleteTagClick = (event, id, type) => {
    event.stopPropagation(); // Stop event from propagating to parent
    const updatedTags = profile.tags.map(tag => {
        if (tag.type === type) {
            return {
                ...tag,
                categories: tag.categories.map(category => ({
                    ...category,
                    tags: category.tags.filter(i => i.id !== id)
                }))
            };
        }
        return tag;
    });

    setProfile({ ...profile, tags: updatedTags });
  };


  return (
    <div className='skill-expertise-interests-container'>
          <h1>What are your skills and interests?</h1>
          <div>Select all strong suits you have that will drive the success of a startup.</div>
          <div className='skills-column-divider'>
            <div className='header-description-card'>
              <h3>Skills & Expertise</h3>
              <div className="divider"></div>
              <div className='skills-expertise-subsection'>
                <div className='skills-container'>
                    {profile.tags && profile.tags.length > 0 ? (
                        (() => {
                        const skillTags = profile.tags
                            .filter(tag => tag.type === 'skill')
                            .flatMap(tag => tag.categories.flatMap(category => category.tags));

                        return skillTags.length > 0 ? (
                            skillTags.map((skill, index) => (
                            <div className='tag skill' key={index}>
                                {skill.display_name}
                                <div
                                    className='delete-button'
                                    onClick={(e) => handleDeleteTagClick(e, skill.id, 'skill')}
                                />
                            </div>
                            ))
                        ) : (
                            <div className='tag skill'>{profile.f_name} has no skills.</div>
                        );
                        })()
                    ) : (
                        <div className='tag skill'>{profile.f_name} has no skills.</div>
                    )}
                    <Select
                        id="skill"
                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'skill')}
                        placeholder="Select the skills that represent your capabilities*"
                        options={defaultSkills}
                        className='skill-dropdown'
                        value="Select the skills that represent your capabilities*"
                    />
                </div>
                <div className='expertise-container'>
                    {profile.tags && profile.tags.length > 0 ? (
                            (() => {
                            const expertiseTags = profile.tags
                                .filter(tag => tag.type === 'expertise')
                                .flatMap(tag => tag.categories.flatMap(category => category.tags));

                            return expertiseTags.length > 0 ? (
                                expertiseTags.map((expertise, index) => (
                                <div className='tag expertise' key={index}>
                                    {expertise.display_name}
                                    <div
                                        className='delete-button'
                                        onClick={(e) => handleDeleteTagClick(e, expertise.id, 'expertise')}
                                    />
                                </div>
                                ))
                            ) : (
                                <div className='tag expertise'>{profile.f_name} has no expertises.</div>
                            );
                            })()
                        ) : (
                            <div className='tag expertise'>{profile.f_name} has no expertises.</div>
                        )}
                    <Select
                        id="expertise"
                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'expertise')}
                        placeholder="Select the expertises you have from entrepreneurism*"
                        options={defaultExpertises}
                        className='expertise-dropdown'
                        value="Select the expertises you have from entrepreneurism*"
                    />
                  </div>
                </div>
            </div>
            <div className='header-description-card'>
							<h3>Interests</h3>
							<div className="divider"></div>
							<div className='interests-subsection'>
								<div className='interests-container'>
                    {profile.tags && profile.tags.length > 0 ? (
                        (() => {
                        const interestTags = profile.tags
                            .filter(tag => tag.type === 'interest')
                            .flatMap(tag => tag.categories.map(category => ({
                            category: category.category,
                            tags: category.tags
                            })));

                        return interestTags.length > 0 ? (
                            interestTags.map((category, index) => (
                            <div key={index} className='category-group'>
                                <h3>{category.category}</h3>
                                {category.tags.map((tag, idx) => (
                                <div className='tag interest' key={idx} style={{ marginBottom: '10px' }}>
                                    {tag.display_name}
                                    <div
                                        className='delete-button'
                                        onClick={(e) => handleDeleteTagClick(e, tag.id, 'interest')}
                                    >
                                    </div>
                                </div>
                                ))}
                            </div>
                            ))
                        ) : (
                            <div className='tag interest'>{profile.f_name} has no interests.</div>
                        );
                        })()
                    ) : (
                        <div className='tag interest'>{profile.f_name} has no interests.</div>
                    )}
                    <Select
                        id="interests"
                        onChange={(selectedOption) => handleTagAdd(selectedOption, 'interest')}
                        placeholder="Select personal interests that help define you*"
                        options={defaultInterests}
                        className='expertise-dropdown'
                        value="Select personal interests that help define you*"
                    />
								</div>
							</div>
						</div>
          </div>
        </div>
  );

};

export default SkillsExpertiseInterests;