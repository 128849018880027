import React, { useState, useRef, useEffect } from 'react';
import classes from './SubMenuPopup.module.scss';

const SubMenuPopup = ({ subMenuVisible, setSubMenuVisible, children }) => {
    const profileMenuRef = useRef();

    // Effect for handling clicks outside the submenu
    useEffect(() => {
        if (subMenuVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [subMenuVisible]);

    const handleClickOutside = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
            setSubMenuVisible(false); // Close the submenu if click happens outside
        }
    };

    return (
        <div className={`${classes.profileMenu} ${subMenuVisible ? `${classes.visible} ${classes.fadeInDownMenu} ${classes.animatedFast}` : ''}`} ref={profileMenuRef}>
            {children}
        </div>
    );
};

export default SubMenuPopup;
