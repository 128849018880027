import React, { useContext, useState} from 'react'
import { Form, Alert} from 'react-bootstrap'
import Recaptcha from 'react-google-invisible-recaptcha';
import AuthContext from 'app/AuthContext';
import { sendInitialMessage } from 'lib/user';
import { sendEventToAnalytics } from 'lib/reporting';
import XClose from "@untitled-ui/icons-react/build/cjs/XClose";
import Mail01 from "@untitled-ui/icons-react/build/cjs/Mail01";
import classes from '../Form.module.scss';
import {Button} from 'sub-components';

const PersonalMessageForm = (props) => {
    // Context neccesary for signing up
    const { authToken, userMetadata, logout} = useContext(AuthContext);

    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [ errors, setErrors ] = useState({});

    // Form inputs
    const [state, setState] = useState({name: '', email: ''});

    // ReCAPTCHA
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_USER_SITE_KEY;
    const refCaptcha = React.useRef();

    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    /**
     * The function `findFormErrors` checks if the length of the `message` is greater than 280
     * characters and returns an error message if it is.
     * @returns The function `findFormErrors` returns an object `newErrors` which contains any input
     * errors found during the validation process.
     */
    const findFormErrors = () => {
        const message = state.message;
        const newErrors = {}
        // Input errors
        if ( message && message.length > 280 ) newErrors.message = 'Message is too long!'
        return newErrors
    }

    /**
     * The function `sendMessage` sends an application for a user to a project, handling success
     * and error cases.
     * @param message - The `message` parameter is a string that represents the message or cover letter
     * that the user wants to include with their application for a project.
     */
    async function sendMessage(message) {
        try {
            const { isSuccess: messageSent, resp: messageResp, err: messageErr } = 
                await sendInitialMessage(
                    { "sender_id": userMetadata.id,
                      "message": message
                    },
                    props.receiverId,
                    authToken
                );

            if (messageSent) {
                props.onActionCompleted();
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Apply project || Success: ", messageResp); }
                if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("Project", "project_applied_to"); }
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Apply project || Error: ", messageResp, messageErr); }
                if (messageResp) {
                    // If auth token is invalid/expired, log the user out.
                    if (messageResp.status_code === "UNAUTHORIZED") {
                        logout();
                    } else {
                        displayError(messageResp.message);
                    }
                } else {
                    displayError("Fatal error, please check back shortly.");
                }
            }
        } catch (error) {
            displayError("Fatal error, please check back shortly.");
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error applying for a project:", error.message); }
        }
   }

    async function onFormSubmit(event) {
        event.preventDefault();
        const newErrors = findFormErrors();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            sendMessage(state.message);
        }
    };

    return (
        <Form onSubmit={onFormSubmit} className={classes.defaultFormFormat}>
            <Form.Group controlId="formBasicMessage">
                <Form.Control 
                    name="Message"
                    placeholder="Explain why you're interested in and why you should talk."
                    type="text" 
                    as="textarea"
                    maxLength={280}
                    rows={3} // Set the number of rows you want to display
                    onChange={(e) => {
                        setState({ ...state, message: e.target.value });
                        setErrors({ ...errors, message: null });
                    }}
                    value={state.message}
                    isInvalid={ !!errors.message}/>
                <Form.Control.Feedback type='invalid'>
                    { errors.message }
                </Form.Control.Feedback>
            </Form.Group>
            <div className={classes.formButtons}>
                <Button className={`${classes.circularButton} ${classes.close}`} onClick={() => props.handleCloseModal()}><XClose/></Button>
                <Button className={`${classes.circularButton} ${classes.send}`} onClick={onFormSubmit}><Mail01/></Button>
            </div>
            <Alert style={{marginBottom: "-26px"}} show={showAlert} variant="danger" className="animated-fast fadeInDownMenu" onClose={() => setShowAlert(false)} ><Alert.Heading style={{fontSize: "13px", color: "red"}}>Error: {alertText}</Alert.Heading></Alert>
            <Recaptcha 
                sitekey={reCaptchaKey}
                ref={refCaptcha}
                onResolved={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Resolved the user."); }
                }}
                onError={() => {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Google ReCAPTCHA || Error occurred."); }
                }}
            />
        </Form>
    );
};

export default PersonalMessageForm;
