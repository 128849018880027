import React from 'react';
import { PageHeader, SectionHeading } from 'components';
import { Layout } from 'layout';

const AccountPreferencesPage = () => {
  const handlePrivacyClick = () => {
    return () => {
      window.location.href = '/privacy-policy';
    };
  };

  return (
    <Layout tabHeader="Account Settings">
      <div className='colored-background'>
        <PageHeader header={"Account Settings"} />
      </div>
      <SectionHeading header="Data & Privacy" />
      <div style={{display: "flex", justifyContent: "center"}}><a style={{cursor: "pointer"}} onClick={handlePrivacyClick()}>See our privacy policy</a></div>
      <div className='general-container' style={{marginBottom: "5%"}}>
        <h3>Request a Copy of Your Data</h3>
        <div>
          If you would like to receive a copy of the personal data we have on file for you, please send an email to <a href="mailto:privacy@sidejawn.io">privacy@sidejawn.io</a> with the subject line "Data Request." Include your full name and the email address associated with your account.
          We will respond to your request within 30 days. Please note that we may require additional information to verify your identity before fulfilling your request.
        </div>
        <h3>Request to Delete Your Account</h3>
        <div>
          If you wish to permanently delete your account and all associated personal data, please send an email to <a href="mailto:privacy@sidejawn.io">privacy@sidejawn.io</a> with the subject line "Account Deletion Request." Be sure to include your full name and the email address associated with your account.
          We will process your account deletion request within [30 days of receiving your email, as outlined in our privacy policy. Please note that deleting your account is a permanent action, and this data cannot be recovered.
        </div>
      </div>
    </Layout>
  );
};

export default AccountPreferencesPage;
