import React, { useContext, useEffect, useState, useRef } from 'react';
import './ProjectFeed.scss';
import { SmallProjectCard } from 'components';
import AuthContext from 'app/AuthContext';
import { FormModal, CreateAccountForm, SignInForm } from 'sub-components';
import Icon from "icons/icon";
import { useLocation, useNavigate } from 'react-router-dom';
//import { SyncLoader } from 'react-spinners';
import { isEqual } from 'lodash';
import { getProjects } from 'lib/project';
import { getEnvConfig } from 'lib/utils';

const ProjectFeed = (props) => {
    // Loading constants
    const loadingTimeoutRef = useRef(null);

    //Project constants
    //const [filters, setFilters] = useState(props.filters);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [projects, setProjects] = useState([]);
    const [startIndex, setStartIndex] = useState(0);

    const [maxNumRecs, setMaxNumRecs] = useState(6);
    const [prevFilters, setPrevCategory] = useState(null);
    
    // User constants
    const { authToken, userMetadata} = useContext(AuthContext);

    // Modal constants
    const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);
    
    // General navigation
    const navigate = useNavigate();
    const location = useLocation();
    // Parse the filters object into a string
    const filtersStr = JSON.stringify(props.filters);
    
    const handleSignUpClick = () => {
		setIsSignUpToggleOn(!isSignUpToggleOn);
		setActionCompleted(false);
	};

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
		setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
	};

    /**
     * The function `handleExplore` checks the current location and user authentication status to
     * either navigate to the discovery page or load more projects.
     * @param e - The parameter "e" is an event object that is passed to the function when it is
     * triggered by an event. It is commonly used to access information about the event, such as the
     * target element or the event type. In this case, it is used to prevent the default behavior of
     * the event,
     */
    const handleExplore = (e) => {
        e.preventDefault();
        if (location.pathname === '/') {
            navigate('/discovery');
        }
        else {
            if (authToken && userMetadata) {
                //Loads more projects
                setStartIndex(projects.length);
            }
            else {
                handleSignUpClick();
            }
        }
    }

    /**
     * The function `loadProjects` is an asynchronous function that takes in filters and a start index
     * as parameters, and it loads projects based on the provided filters and start index.
     * @param filtersStr - The `filtersStr` parameter is a string representation of an object that
     * contains filters for the projects. It is converted back into an object using `JSON.parse()` in
     * the code. The filters object is used to filter the projects based on certain criteria.
     * @param startIndex - The `startIndex` parameter is used to determine the index from which the
     * projects should start loading. It is a numeric value that indicates the position of the first
     * project to be loaded.
     */
    const loadProjects = async (filtersStr, startIndex) => {
        // Initiate the filter option
        let options = [
            { "key": "num_recs", "value": maxNumRecs }
        ];

        // Convert the filters string back into an object
        const filters = JSON.parse(filtersStr);
        if (filters) {
            // Add filters of key/value pairs
            Object.entries(filters).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(function(v) {
                        options.push({ "key": key, "value": v });
                    });
                } else {
                    options.push({ "key": key, "value": value });
                }
            });
        }

        /* Check if the previous category is not equal to the current category. If they are not equal, it means
        that the user has switched to a different category or there is no category selected. In this
        case, the `startIndex` state variable is set to 0, indicating that the projects should start
        loading from the beginning. This is useful for scenarios where the user switches between
        different categories and wants to see the projects from the start of each category. */
        if (!isEqual(prevFilters, filters)) { setStartIndex(0); }

        // Add the start index filter
        options.push({ "key": "index", "value": startIndex })

        // Add a filter to check if the user already applied to each project
        if (userMetadata && userMetadata.id) {
            options.push({ "key": "current_user_id", "value": userMetadata.id });
        }

        setIsLoading(true);

        // Get projects
        const { isSuccess: getProjSuccess, resp: getProjResp, err: getProjErr } = await getProjects(options);

        if (getProjSuccess) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Project Feed Loading || Success: ", getProjResp); }

            if (isEqual(prevFilters, filters)) {
                setProjects(prevProjects => [...prevProjects, ...getProjResp.results]);
            } else if (filters === null) {
                /* Handle the scenario when the user switches between
                different categories or when there is no category selected. */
                if (prevFilters !== null) {
                    setProjects(getProjResp.results);
                } else {
                    setProjects(prevProjects => [...prevProjects, ...getProjResp.results]);
                }
            } else {
                setProjects(getProjResp.results);
            }
            // Store the state of current filter which would become the previous filter for paging purpose
            setPrevCategory(filters);
        } else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Project Search || Error", getProjResp, getProjErr); }
            if (getProjResp) {
                if (getProjResp.status_code === "NOT_FOUND") { setProjects([]); }
            }
            setError(getProjErr);
            setIsLoading(false);
        }
    }

   /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
   `useEffect` hook is used to load projects and show a loading spinner if the projects haven't
   loaded after 2 seconds. */
    useEffect(() => {
        // Only shows the loading spinner if the projects haven't loaded after 2 seconds
        loadingTimeoutRef.current = setTimeout(() => {
            setIsLoading(true);
        }, 2000);
        loadProjects(filtersStr, startIndex);
    }, [filtersStr, startIndex]); // useEffect will run whenever filtersStr or startIndex changes

    useEffect(() => {
        // When projects data changes, set loading to false
        clearTimeout(loadingTimeoutRef.current);
        setIsLoading(false);
    }, [projects]);

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <div className="colorlib-blog">
            <FormModal
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
                <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            
                {/* TO-DO: this is causing "freezing" issue, not allowing user to see/access the feed.
                {isLoading && (
                    <div className="loader-overlay">
                        <SyncLoader color={"#5D9898"} loading={isLoading} size={25} />
                    </div>
                )} */}
                <div className='personal-projects-container'>
                    <div className='gray-vertical-container'>
                        {/* <div className='personal-projects'> */}
                            <div className={`project-cards-feed ${props.scrollType}`}>
                            {projects.length === 0 ? (
                                <div className='not-found-message'>
                                    We can't seem to find these startups or they don't exist. <br/> Post some, or try another category!
                                </div>
                            ) : 
                            (
                                
                                projects.map((project, index) => (
                                    <SmallProjectCard
                                        key={index}
                                        index={index}
                                        projectData={project}
                                        editMode = {false}
                                    />
                                ))
                                
                            )}
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <div className="explore-p">
                <a onClick={handleExplore} className="btn btn-primary btn-outline with-arrow">
                    Explore More
                    <Icon color="white" size={20} className="explore-button" icon="arrow-right2"/>
                </a>
            </div>
        </div>
    )
}

export default ProjectFeed;
