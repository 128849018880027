import React, {useState, useRef, useEffect, useContext} from 'react'
import {SlideDown} from 'react-slidedown'
import Icon from "icons/icon"
import { Link, useNavigate } from 'react-router-dom';
import './Burger.scss'
import 'react-slidedown/lib/slidedown.css'
import AuthContext from 'app/AuthContext'
import profileImage from 'images/blank-profile-picture.png'
import { getProjCategoryOpts } from 'lib/project';
import { formatAvatarUrl } from 'lib/utils';

const Burger = ({ handleSignUpClick, handleSignInClick, handleLogOutClick, handleProfileClick, handleCreateClick }) => {
	const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);
	const [isCategoriesClosed, setIsCategoriesClosed] = useState(true);
	const [isCommunitiesClosed, setIsCommunitiesClosed] = useState(true);
	const [menuClass, setMenuClass] = useState(
		'js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white'
	);
	const navigate = useNavigate();
	const category = getProjCategoryOpts();

	const { authToken, userMetadata} = useContext(AuthContext);
	const wrapperRef = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			if (isMenuDisplayed) {
				toggleBurger();
			}
		}
	};

	const toggleBurger = () => {
		if (isMenuDisplayed) {
			setMenuClass('js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white');
			setIsMenuDisplayed(false);
			setIsCategoriesClosed(true);
			document.body.classList.remove('fixed-body');
		} else {
			setMenuClass('active js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white');
			setIsMenuDisplayed(true);
			document.body.classList.add('fixed-body');
		}
	};

	const toggleCategories = () => {
		setIsCategoriesClosed(!isCategoriesClosed);
	};

	const toggleCommunities = () => {
		setIsCommunitiesClosed(!isCommunitiesClosed);
	};

	const handleSignUp = () => {
		toggleBurger();
		handleSignUpClick();
	};

	const handleSignIn = () => {
		toggleBurger();
		handleSignInClick();
	};

	const handleLogOut = () => {
		toggleBurger();
		handleLogOutClick();
	}

	const handleProfile = () => {
		toggleBurger();
		handleProfileClick();
	}

	const handleCreate = () => {
		toggleBurger();
		handleCreateClick();
	}

	const handleCategoryClick = (category) => {
		toggleBurger();
		if (category.includes('all')) {
			navigate(`/discovery`);
		} else {
			navigate(`/discovery?category=${category}`);
		}
	}

	const handleCommunityClick = (comm) => {
		toggleBurger();
		navigate(`${comm}`);
	}

	return (
		<div ref={wrapperRef} className={isMenuDisplayed ? "overflow offcanvas" : ""}>
			<a onClick= {toggleBurger} className={menuClass}><i></i></a>
			<div id="colorlib-offcanvas" className={isMenuDisplayed ? "" : "closed"}>
			{authToken ? (
				<ul className="actions">
					<li className="profile-container-mobile" onClick={handleProfile}>
						<div className="profile-circle-mobile" style={{backgroundImage: `url(${userMetadata?.user_avatar ? formatAvatarUrl(userMetadata.user_avatar.url, userMetadata.user_avatar.updated_ts) : profileImage})`}}>
						</div>
						{userMetadata && userMetadata.display_name && (
							<div className="user-name">{userMetadata.display_name}</div>
						)}
					</li>
					<li><a style={{cursor:'pointer'}} onClick={handleCreate}>Post pitch</a></li>
					<li><a style={{cursor:'pointer'}} onClick={handleLogOut}>Log out</a></li>
				</ul>
				) : (
				<ul className="actions">
					<li><a style={{cursor:'pointer'}} onClick={handleSignUp}>Sign up</a></li>
					<li><a style={{cursor:'pointer'}} onClick={handleSignIn}>Log in</a></li>
				</ul>
			)}
			<hr className="menu-division"/>
			<ul>
				<li><Link onClick= {toggleBurger} to="/">Home</Link></li>
				<li><Link onClick= {toggleBurger} to="/about">About</Link></li>
				<li><Link onClick= {toggleBurger} to="/resources">Resources</Link></li>
				<li className="" >
				<a style={{cursor:'pointer'}} onClick= {toggleCategories}>Startups <Icon color="white" className="dropdown-icon" size={10} icon="circle-down"/></a>
					<SlideDown className={'pure-menu pure-menu-scrollable dropdown-slidedown'} closed={isCategoriesClosed}>
						<ul>
							<li><a style={{cursor:'pointer'}} onClick={() => handleCategoryClick('all')}>All</a></li>
							{category.map(item => (
								<li key={item.value}>
									<a style={{cursor:'pointer'}} onClick={() => handleCategoryClick(item.value)}>{item.label}</a>
								</li>
							))}
						</ul>
					</SlideDown>
				</li>
				<li className="" >
					<a style={{cursor:'pointer'}} onClick= {toggleCommunities}>Communities <Icon color="white" className="dropdown-icon" size={10} icon="circle-down"/></a>
					<SlideDown className={'pure-menu pure-menu-scrollable dropdown-slidedown'} closed={isCommunitiesClosed}>
						<ul>
							<li><a style={{cursor:'pointer'}} onClick={() => handleCommunityClick('/venture-cafe')}>Venture Cafe</a></li>
							<li><a style={{cursor:'pointer'}} onClick={() => handleCommunityClick('/founder-institute')}>PSL & Founder Institute</a></li>
						</ul>
					</SlideDown>
				</li>
			</ul>
			</div>
		</div>
	)
}
export default Burger;