import React, { useEffect, useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { FormModal, CreateAccountForm, SignInForm, PersonalMessageForm } from 'sub-components';
import { UserProfileVideoOverlayed } from 'components';
import AuthContext from 'app/AuthContext';
import { getEnvConfig } from 'lib/utils';
import classes from './DiscoveryPage.module.scss';
import { getUserRecommended } from 'lib/user';
import Share06 from "@untitled-ui/icons-react/build/cjs/Share06";
import Mail01 from "@untitled-ui/icons-react/build/cjs/Mail01";

const DiscoveryPage = () => {
    const { authToken, userMetadata } = useContext(AuthContext);
    const loadingTimeoutRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [isApplicationToggleOn, setApplicationToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [userVideos, setUserVideos] = useState([]);
    const [direction, setDirection] = useState(1); // 1 for down, -1 for up
    const [notificationVisible, setNotificationVisible] = useState(false); // State for notification
    const containerRef = useRef(null);
    const videoRefs = useRef([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [playingStates, setPlayingStates] = useState(() =>
        userVideos.map((_, index) => index === 0 ? false : false)
    );

    // useEffect(() => {
    //     if (!authToken) {
    //         if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("No auth token found. Please login."); }
    //         // handleSignUpClick();
    //         handleSignInClick();
    //     }
    // }, [authToken]); // useEffect will run whenever authToken changes


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }    

    const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
    };

    const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
        }
    };

    // Snapping logic
    const handleScroll = () => {
        if (!containerRef.current) return;
    
        const container = containerRef.current;
        const videoHeight = container.clientHeight;
        const scrollTop = container.scrollTop;
    
        // Calculate the index of the closest video
        const nextIndex = Math.round(scrollTop / videoHeight);
    
        // If index changes, update and snap
        if (nextIndex !== currentVideoIndex) {
          setCurrentVideoIndex(nextIndex);
          container.scrollTo({
            top: nextIndex * videoHeight,
            behavior: "smooth",
          });
        }
    };

    useEffect(() => {
        const container = containerRef.current;
    
        // Attach scroll event listener
        if (container) {
          container.addEventListener("scroll", handleScroll, { passive: false });
        }
    
        // Cleanup on component unmount
        return () => {
          if (container) {
            container.removeEventListener("scroll", handleScroll);
          }
        };
    }, [currentVideoIndex]);

    useEffect(() => {
        // Ensure the container snaps to the current video index on mount or update
        if (containerRef.current) {
          const videoHeight = containerRef.current.clientHeight;
          containerRef.current.scrollTo({
            top: currentVideoIndex * videoHeight,
            behavior: "instant",
          });
        }
      }, [currentVideoIndex]);

    // Share video function copies the profile link to the clipboard
    const shareVideo = () => {
        const currentProfileEndpoint = process.env.REACT_APP_ENVIRONMENT === "dev" ? "http://localhost:3000/profile/" : "https://www.sidejawn.io/profile/";
        const profileLink = currentProfileEndpoint + userVideos[currentVideoIndex]?.profileMetadata.userId;
    
        // Check if navigator.clipboard is available
        if (navigator.clipboard) {
            navigator.clipboard.writeText(profileLink).then(() => {
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method
            const textarea = document.createElement('textarea');
            textarea.value = profileLink;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy'); // Attempt to copy
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            } catch (err) {
                console.error('Fallback: Unable to copy: ', err);
            }
            document.body.removeChild(textarea); // Clean up
        }
    };

    const messagePerson = () => {
        if (authToken) {
            openApplicationForm();
        } else {
            handleSignInClick();
        }
    };

    const openApplicationForm = () => {
        setApplicationToggleOn(!isApplicationToggleOn);
        setActionCompleted(false);
    };

    const handleApplyCompleted = () => {
		setActionCompleted(true);
        // setDisableApplyButton(true);
        // setApplyButtonVerbage("Applied");
        // setShowCheckMark(true);
        // props.setIsApplied(true);
	};

    const loadUserVideos = async () => {
        // TODO: Create better way to track a guest user
        const { isSuccess: loadVideosSuccess, resp: recommendedVideosResp, err: loadVideosError } =  await getUserRecommended(userMetadata?.id ? userMetadata.id : 'guest-id', 'videos', authToken ? authToken : 'guest-auth');
        if (loadVideosSuccess) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Video Recs Loading || Success", recommendedVideosResp); }
            const extendedVideos = [
                ...recommendedVideosResp.results,
                { id: "no-more-videos", isPlaceholder: true }, // Add a placeholder at the end
            ];
            setUserVideos(extendedVideos);
            console.log(extendedVideos);
        }
        else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Error loading user video recs", loadVideosError); }
        }
    };

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
   `useEffect` hook is used to load projects and show a loading spinner if the projects haven't
   loaded after 2 seconds. */
   useEffect(() => {
        // Only shows the loading spinner if the projects haven't loaded after 2 seconds
        loadingTimeoutRef.current = setTimeout(() => {
            setIsLoading(true);
        }, 2000);
        loadUserVideos(startIndex);
    }, [userMetadata]);

    useEffect(() => {
        // When user videos data changes, set loading to false
        clearTimeout(loadingTimeoutRef.current);
        setIsLoading(false);
    }, [userVideos]);

    useEffect(() => {
        const observerOptions = {
            root: containerRef.current,
            rootMargin: '0px',
            threshold: 0.9,
        };
    
        const observer = new IntersectionObserver((entries) => {
            setPlayingStates((prevStates) => {
                // Start with a copy of the previous states
                const newStates = [...prevStates];
        
                let isPlaceholderVisible = false;
        
                entries.forEach((entry) => {
                    const index = parseInt(entry.target.getAttribute('data-index'), 10);
        
                    // Check if the placeholder (noVideos) is visible
                    if (userVideos[index]?.isPlaceholder && entry.isIntersecting) {
                        isPlaceholderVisible = true;
                    } else if (!userVideos[index]?.isPlaceholder) {
                        // For normal videos
                        newStates[index] = entry.isIntersecting;
                    }
                });
        
                // If placeholder is visible, pause all videos
                if (isPlaceholderVisible) {
                    for (let i = 0; i < newStates.length; i++) {
                        newStates[i] = false;
                    }
                }
        
                console.log('Updated States: ', newStates);
                return newStates;
            });
        }, observerOptions);
    
        // Observe all video wrappers
        videoRefs.current.forEach((videoWrapper, index) => {
            if (userVideos[index]?.isPlaceholder) return; // Skip placeholder
            if (videoWrapper) observer.observe(videoWrapper);
        });
    
        return () => {
            videoRefs.current.forEach((videoWrapper) => {
                if (videoWrapper) observer.unobserve(videoWrapper);
            });
        };
    }, [userVideos]);

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <div className={classes.discoveryContainer}>
            <FormModal
                backdrop='static'
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
			    <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                isToggleOn={isApplicationToggleOn}
                handleClick={openApplicationForm}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title="Send a Message"
                successfulActionTitle="Message Sent!"
                successfulActionMessage="Check your email for next steps, and keep exploring!">
                <PersonalMessageForm
                    onActionCompleted={handleApplyCompleted}
                    receiverId={userVideos[currentVideoIndex]?.profileMetadata.userId}
                />
            </FormModal>
            <div className={classes.headerSection}>
                <div className={classes.headerText}>
                    <h1>Explore</h1>
                    <div className={classes.subText}>Connect with all types of people.</div>
                </div>
                <div className='horizontal-divider'></div>
                <div className={classes.sideMenu}>
                    <ul>
                        {/* TODO: Add when we add more filters */}
                        <li><a href="/discovery">Explore</a></li>
                        <li><a style={{cursor: 'default'}}>(More coming soon)</a></li>
                    </ul>
                </div>
            </div>
            <div className={classes.mainContent}>
                {/* Loading spinner or no video message */}
                {userVideos.length === 0 ? (
                    <div className={classes.noVideos}>No more jawns for you, for now! <br/> We'll email you when your next batch is available.</div>
                ) : (
                    <div className={classes.interactionContainer}>
                        <div
                            ref={containerRef}
                            className={classes.videoContainer}
                            >
                            {userVideos?.map((video, index) => (
                                <div
                                key={video.id}
                                className={classes.videoWrapper}
                                ref={(el) => (videoRefs.current[index] = el)}
                                data-index={index}
                                >
                                    {!video.isPlaceholder ? (
                                        <UserProfileVideoOverlayed
                                        pitchVideo={video.userVideo}
                                        pitchMetadata={video.profileMetadata}
                                        isPlaying={playingStates[index]} // Pass playing state
                                        />
                                    ) : (
                                        <div className={classes.noVideos}>
                                            No more jawns for you, for now! <br />
                                            We'll email you when your next batch is available.
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={classes.videoNavButtons}>
                            <button onClick={messagePerson}><Mail01/></button>
                            <button onClick={shareVideo}><Share06/></button>
                        </div>
                    </div>
                )}
            </div>
            {/* Notification for link copied */}
            {notificationVisible && (
                <div className={classes.notification}>
                    Link copied to clipboard!
                </div>
            )}
        </div>
    );
}

export default DiscoveryPage;
