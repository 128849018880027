import React, { useState, useContext, useEffect } from "react";
import "./LargeProjectCard.scss";
import projectIcon1 from "icons/project-icon-1.png";
import pinnedLocation from "icons/pinned-location.png";
import webGlobe from "icons/web-globe.png";
import { FormModal, ApplicationForm, CreateAccountForm, SignInForm } from "sub-components";
import Icon from 'icons/icon';
import AuthContext from 'app/AuthContext';
import { Button, Alert } from 'react-bootstrap';
import blankprofileImage from 'images/blank-profile-picture.png';
import { getEnvConfig, formatAvatarUrl } from 'lib/utils';
import { getTitleAndGoal } from 'lib/user';

const LargeProjectCard = (props) => {
    const {authToken, userMetadata} = useContext(AuthContext);
    const [disableApplyButton, setDisableApplyButton] = useState(props.projectData.applied || props.isApplied);
    const [applyButtonVerbage, setApplyButtonVerbage] = useState( props.projectData.applied || props.isApplied ? "Collabed" : "Collab")
    const [showCheckMark, setShowCheckMark] = useState(props.projectData.applied ? props.projectData.applied : props.isApplied);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [isApplicationToggleOn, setApplicationToggleOn] = useState(false);
    const [userTitle, setUserTitle] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    let finalAvatarURL =
        props.projectData.user_avatar?.url !== null && props.projectData.user_avatar?.url !== undefined
        ? formatAvatarUrl(props.projectData.user_avatar.url, props.projectData.user_avatar.updated_ts)
        : blankprofileImage;

    // Set user title
    useEffect(() => {
        if (props.projectData.user_tags?.length > 0) {
            const { title } = getTitleAndGoal(props.projectData.user_tags);
            setUserTitle(title);
        }
    }, [props.projectData.user_tags]);

    // Error handling
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

    const handleSignUpClick = () => {
		setIsSignUpToggleOn(!isSignUpToggleOn);
		setActionCompleted(false);
	};

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
		setActionCompleted(true);
        setIsSignUpToggleOn(false);
		// const id = setTimeout(() => {
		//     setIsSignUpToggleOn(false);
		// }, 10000000);
		// setTimeoutId(id);
	};

    const handleApplyClick = () => {
        if(userMetadata.id === props.projectData.owner_id) {
          displayError("You cannot apply to your own startup.");
          return;
        }
        else {
          openApplicationForm();
        }
    };

    const handleAuthorClick = () => {
        if(!props.editMode) {
            window.open('/profile/' + props.projectData.owner_id);
        }
    }

    const handleLinkClick = () => {
        window.open(`${props.projectData.link}`);
    }

    const handleClick = (actionType) => {
        if(actionType === 'apply'){
            if (authToken && userMetadata) {
                handleApplyClick(props.index);
            } else {
                handleSignInClick();
            }
        } else if (actionType === 'edit'){
            props.handleEditProjectClick(props.index);
        }
    };

    const openApplicationForm = () => {
        setApplicationToggleOn(!isApplicationToggleOn);
        setActionCompleted(false);
    };

    const handleApplyCompleted = () => {
		setActionCompleted(true);
        setDisableApplyButton(true);
        setApplyButtonVerbage("Applied");
        setShowCheckMark(true);
        props.setIsApplied(true);
	};

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <div className="large-project-card">
            <FormModal
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
                <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                isToggleOn={isApplicationToggleOn}
                handleClick={openApplicationForm}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title="Send a Message"
                successfulActionTitle="Application Submitted"
                successfulActionMessage="Check your email for next steps.">
                <ApplicationForm onActionCompleted={handleApplyCompleted} projectId={props.projectData.project_id}/>
            </FormModal>
                <div className="content-wrapper" onClick={e => e.stopPropagation()}>
                    <div className="card-popup">
                        <div className="card-popup-v">
                            <div className="top-section">
                                <div className="card-header-info">
                                    <img className="img" alt="Project Icon" src={projectIcon1} />
                                    <div className="project-headers">
                                        <div className="major-tags">
                                            <div className="blue-tag-wrapper">
                                                <div className="category-wrapper">{props.projectData.category ? props.projectData.category: "Unknown"}</div>
                                            </div>
                                            <div className="green-tag-wrapper">
                                                <div className="status-wrapper">{props.projectData.status ? props.projectData.status: "Unknown"}</div>
                                            </div>
                                            {props.editMode ? ( props.projectData.visibility === "public"? (<span id="visibility" className='public'>Public</span>):(<span id="visibility" className='draft'>Draft</span>)):(<span></span>)}
                                        </div>
                                        <div className="title-wrapper">{props.projectData.title}</div>
                                        <div className="sub-heading">
                                            <div className="date">Posted: {props.projectData.date_posted}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-metadata">
                                    {props.projectData.city && props.projectData.province && 
                                        <div className="metadata-wrapper">
                                            <img className="small-icon" alt="Frame" src={pinnedLocation} />
                                            <div className="metadata-item-wrapper">{props.projectData.city}, {props.projectData.province}</div>
                                        </div>
                                    }
                                    {props.projectData.link && 
                                        <div className="metadata-wrapper">
                                            <img className="small-icon" alt="Frame" src={webGlobe} />
                                            <a className="metadata-item-wrapper project-link" onClick={handleLinkClick}>{props.projectData.link}</a>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="project-main-info-section">
                                <div className="description-wrapper">
                                    <div className="main-info-header">What We Are</div>
                                    <p className="p">{props.projectData.description}</p>
                                </div>
                                <div className="main-info-wrapper">
                                    <div className="main-info-header">Why We’re Doing This</div>
                                    <p className="p">
                                        {props.projectData.mission_statement}
                                    </p>
                                </div>
                                <div className="main-info-wrapper">
                                    <div className="main-info-header">How We’re Doing It</div>
                                    <p className="p">
                                        {props.projectData.solution}
                                    </p>
                                </div>
                                <div>
                    </div>
                            </div>
                            <div className="recruiting-for-section">
                                <div className="main-info-header">Recruiting For</div>
                                <div className='gray-vertical-container-small'>
                                    <div className='personal-projects-container'>
                                        <div className='project-cards-feed horizontal-scrolling'>
                                        {/* <div className="roles-section">
                                            <div className="scroll-wrapper"> */}
                                                {props.projectData.recruiting_roles.map((roleObj, index) => (
                                                <div key={index} className="profile-skillset profile-description-card-height">
                                                    <div className="roles-item">{roleObj.role_name ? roleObj.role_name: "Role"}</div>
                                                    <p className="role-description">
                                                    {roleObj.description}
                                                    </p>
                                                </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div>
                        <div className="footer-wrapper">
                            <div className="footer">
                                <div className="avatar">
                                    <img className="avatar-image" alt="Placeholder image" onClick={handleAuthorClick} src={finalAvatarURL} />
                                    <div className="content">
                                        <div className="name" onClick={handleAuthorClick} >{props.projectData.manager_display_name}</div>
                                        <div className="manager-wrapper">
                                            <div className="title">{userTitle}</div>
                                        </div>
                                    </div>
                                </div>
                                {props.editMode ? 
                                    (<Button variant="primary" className="text-3 primary-button" onClick={() => handleClick('edit')} centered="true" disabled={disableApplyButton}>Edit</Button>) 
                                    :
                                    (<Button className="text-3 primary-button" onClick={() => handleClick('apply')} disabled={disableApplyButton || props.editMode}> {showCheckMark && <Icon color="white" size={11} className="applied-checkmark" icon="checkmark"/>} {applyButtonVerbage}</Button>)
                                }
                            </div>
                        </div>
                        <div className="alert-wrapper">
                            <Alert show={showAlert} variant="danger" className="animated-fast fadeInDownMenu custom-alert" onClose={() => setShowAlert(false)} >
                                <Alert.Heading style={{fontSize: "13px", color: "red", textAlign: "center"}}>Oops! {alertText}</Alert.Heading>
                            </Alert>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default LargeProjectCard;