import React from 'react';
import './Button.scss'; // Assuming you're using a separate CSS/SCSS file for styling

const Button = ({ children, className, ...rest }) => {
  return (
    <button className={`btn ${className}`} {...rest}>
      {children} {/* Render whatever is passed between the <Button> tags */}
    </button>
  );
};

export default Button;
