import React, { useState, useEffect } from 'react';
import { PageHeader, TrendingProjectsFeed, SectionHeading, CallToAction, Communities } from 'components';
import { Layout } from 'layout';
import pitchForm from 'images/post-pitch-screenshot.png';
import pitchFormMobile from 'images/post-pitch-screenshot.png';
import swipingVideo from 'images/swiping-hero-animation.mp4';
import networkBenefits from 'images/feature-benefits.png';

const EarlyLandingPage = () => {
    const [isSignInToggleOnPrompt, setIsSignInToggleOnPrompt] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [isNow, setIsNow] = useState(true);

    useEffect(() => {
        const setLoginPrompt = () => {
            const params = new URLSearchParams(window.location.search);
            const promptLogin = params.get('prompt_login');
            
            if (promptLogin === 'true') {
                setIsSignInToggleOnPrompt(true);
                setRedirectURL(params.get('redirect'));
            }
        };

        setLoginPrompt();
    }, []);

    const handleToggle = () => {
        setIsNow(!isNow);
    };

    return (
        <Layout tabHeader="Home Page">
            <div className="main-page">
                <div className='colored-background header-section'>
                    <PageHeader 
                        displayDiscoveryBtn={'inline'} 
                        mainPhoto={swipingVideo}
                        preHeader={"Life's a pitch by yourself."}
                        header={"Pitch here, find partners \n & build together."}
                        subHeader={"Find your people, fuel your passion."}
                    />
                    {/* <Banner/> */}
                </div>
                <Communities />
                <div className='pitch-simplification-wrapper'>
                    <SectionHeading 
                        header="Your pitch, simplified."
                        subHeading="Introduce yourself to the community with 4 topics."
                    />
                    <picture>
                        <source srcSet={pitchFormMobile} media="(max-width: 900px)" />
                        <img src={pitchForm} alt="Pitch prompts"/>
                    </picture>
                </div>
                <div className="gray-container">
                    <div className='network-benefits-section'>
                        <SectionHeading 
                            header="Purposeful connections, stronger community."
                            subHeading={"Where pitches lead to intentional collaboration."}
                        />
                        <img src={networkBenefits} alt="Network Benefits" className="video-network" />
                    </div>
                </div>
                <div className='join-now-wrapper'>
                    <SectionHeading header="Join over 150+ community members."/>
                    <div className='skill-tags-section'>
                        <div className='skill-tags'>
                            <div>
                                Sales & Marketing
                            </div>
                            <div>
                                Software Developers
                            </div>
                            <div>
                                UI/UX Designers
                            </div>
                            <div>
                                Product Specialists
                            </div>
                            <div>
                                Advisors
                            </div>
                            <div>
                                Mentors
                            </div>
                            <div>
                                Creatives
                            </div>
                            <div>
                                Operations Managers
                            </div>
                            <div>
                                University Students
                            </div>
                        </div>
                    </div>
                    <CallToAction 
                    actionButtonTitle="Join them now" 
                    userActionButtonTitle="Give Us Feedback" 
                    imageDesc="sidejawn partners connecting through startup"
                    />
                </div>
            </div>
        </Layout>
    );
};

export default EarlyLandingPage;