import React, {useState, useEffect, useContext, useRef} from "react";
import { Layout } from 'layout';
import classes from './UserProfilePage.module.scss'
import { uploadUserVideo } from 'lib/user';
import { getUserContents, getContentsByCategory} from 'lib/content';
import { UserProfileData, UserProfileVideo } from 'components';
import { LargeModal, Button, SmallModal } from 'sub-components';
import AuthContext from 'app/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import FaceSmile from "@untitled-ui/icons-react/build/cjs/FaceSmile";
import HeartHand from "@untitled-ui/icons-react/build/cjs/HeartHand";
import Rocket02 from "@untitled-ui/icons-react/build/cjs/Rocket02";
import SearchRefraction from "@untitled-ui/icons-react/build/cjs/SearchRefraction";
import CheckCircle from "@untitled-ui/icons-react/build/cjs/CheckCircle";

const UserVideoEdit = (props) => {
	const [showLargeCard, setShowLargeCard] = useState(false);
	const {userId} = useParams();
	const { authToken, userMetadata} = useContext(AuthContext);
	const loadingTimeoutRef = useRef(null);
	const [progress, setProgress] = useState(0); // Progress state for video upload
	const [isUploading, setIsUploading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState("");
	const pitchingTutorial = 'https://sj-profile-video-dev.s3.us-east-1.amazonaws.com/new-pitch-posting-tutorial.mp4';

	// Video upload constraints
    const maxFileSize = 300 * 1024 * 1024; // 250 MB in bytes
    const maxDuration = 60; // 1 minute in seconds

	// State to track whether the screen size is smaller than 1023px
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1023);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [pitchVideo, setPitchVideo] = useState({
		videoId: "",
		videoUrl: "",
	});

	// useEffect to track window resize events and update screen size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1023);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

	// TODO: This causes a backend API call, maybe use userMetata in local storage or state of data or don't do anythign at all besides closing the edit mode?
    const handleCancel = () => {
        navigate(`/profile/${userId}?is_profile_view=true`);
    }

	// Handles file selection
	const handleFileChange = async (event) => {
		// If there is no file selected, return
		const file = event.target.files[0];
		if (!file) return;

		// Check file size
		if (file.size > maxFileSize) {
			setErrorMessage("The video file is too large. Please upload a video under 250 MB.");
			return;
		}

		// Set the local file location to display on the current video player
		const video = document.createElement("video");
		video.preload = "metadata";
		video.src = URL.createObjectURL(file);

		video.onloadedmetadata = () => {
			// Revoke the object URL to free up memory
			window.URL.revokeObjectURL(video.src);

			// Check video duration
			if (video.duration > maxDuration) {
				setErrorMessage("The video is too long. Please upload a video under 1 minute.");
			} else {
				// Clear any previous errors
				setErrorMessage("");

				// Set pitchVideo to the file URL for display
				const videoURL = URL.createObjectURL(file);
				setPitchVideo((prev) => ({
					...prev,
					videoUrl: videoURL, // Update only the userVideo field
				}));

				// Store the file in state for uploading
				setSelectedVideo(file);

				console.log("Video is valid and ready for upload:", file);
			}
		};

		video.onerror = () => {
			setErrorMessage("Invalid video file. Please try again.");
		};
	};

	// Handles video upload
	const handleSubmit = async () => {
		// Reset loading state and progress bar
		setIsUploading(true);
		setProgress(0);

		const duration = 60; // Estimated time (in seconds) for API call
		const increment = 100 / (duration * 10); // Increment every 100ms; yes this is a fake progress bar
		const interval = setInterval(() => {
			setProgress((prev) => Math.min(prev + increment, 99)); // Cap progress at 99% before completion
		}, 100);

		try {
			if (!selectedVideo) {
				setErrorMessage("Please select a video before submitting.");
				clearInterval(interval); // Clear progress interval
				setIsUploading(false); // Stop progress bar
				return;
			}
	
			setErrorMessage(""); // Clear previous error messages
	
			// Simulated API call
			const { isSuccess, resp, err } = await uploadUserVideo(
				pitchVideo.videoId,
				selectedVideo,
				userId,
				authToken
			);
	
			if (isSuccess) {
				console.log("Video uploaded successfully!", resp);
				setProgress(100); // Set progress to 100% on success
				setDisplaySuccessModal(true);
			} else {
				console.error("Failed to upload video:", err);
				setErrorMessage(
					"An error occurred while uploading the video. Please try again."
				);
			}
		} catch (error) {
			console.error("An unexpected error occurred:", error);
			setErrorMessage("An unexpected error occurred. Please try again.");
		} finally {
			clearInterval(interval); // Clear progress interval
			setTimeout(() => {
				setIsUploading(false); // Reset loading state after a short delay
			}, 500);
		}
	};


    const loadUserVideo = async () => {
        const { isSuccess: getContentsSuccess, resp: getContentsResp, err: getContentsErr } = await getUserContents(userId, [{ "key": "category", "value": "pitch_video" }], authToken);
		if (getContentsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Content Search || Success", getContentsResp); }
			if (getContentsResp.results != null && getContentsResp.results) {
				setPitchVideo({
					videoId: getContentsByCategory(getContentsResp.results, 'pitch_video')[0].id,
					videoUrl: getContentsByCategory(getContentsResp.results, 'pitch_video')[0].link
				})
			}
		} else {
			setPitchVideo({
				videoId: "",
				videoUrl: pitchingTutorial
			})
		}
    };


	/* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
	`useEffect` hook is used to load projects and show a loading spinner if the projects haven't
	loaded after 2 seconds. */
	useEffect(() => {
		// Only shows the loading spinner if the projects haven't loaded after 2 seconds
		loadingTimeoutRef.current = setTimeout(() => {
			setIsLoading(true);
		}, 2000);
		loadUserVideo();
	}, []);

	return (
		<Layout tabHeader="Profile">
			{ displaySuccessModal && (
				<SmallModal 
					setDisplayModal={setDisplaySuccessModal} 
					display={displaySuccessModal} 
					hideCloseBtn={true}
					onCloseAction={() => navigate(`/profile/${userId}?is_profile_view=true`)}
				>
					<div className={classes.successMessage}>
						<h3>Posted submitted!</h3>
						<CheckCircle className={classes.icon}/>
					</div>
				</SmallModal>
			)}
			{  isUploading && (
				<SmallModal 
					setDisplayModal={setDisplaySuccessModal} 
					display={displaySuccessModal} 
					hideCloseBtn={true}
					onCloseAction={() => navigate(`/profile/${userId}?is_profile_view=true`)}
					hideOkButton={true}
				>
					<div className={classes.successMessage}>
						<p>Do not leave this screen while we upload your video, pretty please.</p>
						<div
							style={{
								borderRadius: '10px',
								marginTop: "10px",
								width: "300px",
								background: "#e0e0e0",
								height: "20px",
							}}
						>
							<div
								style={{
									borderRadius: '10px',
									width: `${progress}%`,
									background: "#76c7c0",
									height: "100%",
									transition: "width 0.1s linear",
								}}
							/>
						</div>
					</div>
				</SmallModal>

			)}
			{/* Conditionally render LargeModal only for small screens */}
			{isSmallScreen && (
				<div className={classes.userProfilePageContainer}>
					<UserProfileVideo pitchVideo={pitchVideo.videoUrl} />
					<Button
						style={{ margin: 'auto' }}
						className="btn-primary btn-lg"
						onClick={() => document.getElementById('video-upload').click()}
					>
						Select a video
					</Button>
					{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
					<input
						id="video-upload"
						type="file"
						accept="video/*"
						style={{ display: 'none' }}
						onChange={handleFileChange}
					/>
					<div className={classes.dataContainer}>
						<h2 className={classes.promptTitle}>Your 1 Minute Pitch</h2>
						<div className={classes.videoPrompt}>
							<div className={classes.promptContainer}>
								<FaceSmile className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										Who are you?
									</div>
									<div className={classes.promptSubtext}>
										What's your name and what are you good at?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<Rocket02 className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										What are you up to?
									</div>
									<div className={classes.promptSubtext}>
										Do you have an idea or are you working on something?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<SearchRefraction className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										What do you need?
									</div>
									<div className={classes.promptSubtext}>
										Who are you looking for and why?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<HeartHand className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										Gift for the community?
									</div>
									<div className={classes.promptSubtext}>
										Any skills or talents you can offer to others?
									</div>
								</div>
							</div>
							<div className={classes.actionButtons}>
								<Button className='secondary' onClick={handleCancel}>Cancel</Button>
								<Button className="btn btn-primary btn-md" 
									onClick={handleSubmit}
									disabled={isUploading}
								>
									{isUploading ? "Uploading..." : "Post Pitch"}
								</Button>
							</div>
							<div className={classes.buttonSection}>
								{errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
							</div>
						</div>
					</div>
					{showLargeCard && (
						<LargeModal setShowLargeCard={setShowLargeCard}>
							<UserProfileData />
						</LargeModal>
					)}
				</div>
			)}
			{/* For larger screens, render normally */}
			{!isSmallScreen && (
				<div className={classes.userProfilePageContainer}>
					<div className={classes.videoContainer}>
						<UserProfileVideo pitchVideo={pitchVideo.videoUrl} />
						<div className={classes.buttonSection}>
							{errorMessage && <div className={classes.errorMessage}>{errorMessage}</div>}
						</div>
						<input
							id="video-upload"
							type="file"
							accept="video/*"
							style={{ display: 'none' }}
							onChange={handleFileChange}
						/>
					</div>
					<div className={classes.dataContainer}>
						<h2 className={classes.promptTitle}>Your 1 Minute Pitch</h2>
						<div className={classes.videoPrompt}>
							<div className={classes.promptContainer}>
								<FaceSmile className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										Who are you?
									</div>
									<div className={classes.promptSubtext}>
										What's your name and what are you good at?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<Rocket02 className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										What are you up to?
									</div>
									<div className={classes.promptSubtext}>
										Do you have an idea or are you working on something?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<SearchRefraction className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										What do you need?
									</div>
									<div className={classes.promptSubtext}>
										Who are you looking for and why?
									</div>
								</div>
							</div>
							<div className={classes.promptContainer}>
								<HeartHand className={classes.icon}/>
								<div className={classes.promptSubcontainer}>
									<div className={classes.promptText}>
										Gift for the community?
									</div>
									<div className={classes.promptSubtext}>
										Any skills or talents you can offer to others?
									</div>
								</div>
							</div>
						</div>
						<div className={classes.actionButtons}>
							<Button className='secondary' onClick={handleCancel}>Cancel</Button>
							<Button
								className="btn-primary btn-lg"
								onClick={() => document.getElementById('video-upload').click()}
								disabled={isUploading}
							>
								Select a video
							</Button>
							<Button className="btn btn-primary btn-lg" 
								onClick={handleSubmit}
								disabled={isUploading}
							>
								{isUploading ? "Uploading..." : "Post Pitch"}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default UserVideoEdit;
