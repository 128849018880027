import './Banner.scss';

const Banner = () => {
  return (
    <div className='minor-note-section'>
        <div>
        We connect co-founders, business partners, mentors, and advisors for new ventures. <div className='horizontal-divider' style={{marginTop: "3px", marginBottom: "3px"}}/> <strong style={{color:'#5D9898'}}>Side jawn</strong> can be used as Philly slang for <strong style={{color:'#5D9898'}}>side project or startup</strong>.
        </div>
    </div>
  );
};

export default Banner;