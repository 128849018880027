import React, {useState, useEffect} from "react";
import "./SmallProjectCard.scss";
import {LargeProjectCard} from "components";
import {LargeModal} from "sub-components";
import projectIcon1 from "icons/project-icon-1.png";
import arrowDown from "icons/small-dropdown-downwards-arrow.png";
import blankprofileImage from 'images/blank-profile-picture.png';
import { formatAvatarUrl } from 'lib/utils';
import { getTitleAndGoal } from 'lib/user';

const SmallProjectCard = ({
		index,
		className = '',
		projectData,
		editMode = false,
		handleEditProjectClick
	}) => {

	const [userTitle, setUserTitle] = useState('');
	
	const {
		category = 'Unknown Category',
		title = "Unknown Title",
		description = 'Unknown description',
		status = 'Uknown Status',
		date_posted = '7/31/23',
		manager_display_name = 'Unknown Name',
		recruiting_roles = [{'role_name': 'Unknown Roles'}],
		user_avatar =  {url: blankprofileImage},
		visibility = 'private',
		city = 'Unknown City',
		province = 'Unknown Province',
		owner_id = '',
		applied = false,
		user_tags = [],
	} = projectData || {};

	const [showDropdown, setShowDropdown] = useState(false);
	const [showLargeCard, setShowLargeCard] = useState(false);
	const [isApplied, setIsApplied] = useState(applied);
	let finalAvatarURL = user_avatar && user_avatar.url !== null
  		? formatAvatarUrl(user_avatar.url, user_avatar.updated_ts)
  		: blankprofileImage;

	useEffect(() => {
		if (user_tags?.length > 0) {
			const { title } = getTitleAndGoal(user_tags);
			setUserTitle(title);
		}
	}, [user_tags]);

	useEffect(() => {
		if (showLargeCard) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		return () => {
			// Cleanup to enable scrolling when this component unmounts for any reason.
			document.body.style.overflow = 'auto';
		};
	}, [showLargeCard]);

	// Handler function for the dropdown click
	const handleDropdownClick = (event) => {
		event.stopPropagation(); // Stop event from propagating to parent
		setShowDropdown(!showDropdown);
	};

	const handleAuthorClick = (event) => {
		event.stopPropagation(); // Stop event from propagating to parent
		if(!editMode) {
			window.open('/profile/' + owner_id);
		}
	}

	return (
		<div className="small-card-wrapper">
		{showLargeCard && 
			<LargeModal setShowLargeCard={setShowLargeCard}>
				<LargeProjectCard
					index={index}
					className={className}
					projectData={projectData}
					editMode={editMode}
					isApplied={isApplied}
					setIsApplied={setIsApplied}
					handleEditProjectClick={handleEditProjectClick}
				/>
			</LargeModal>
		}
		<div className="small-card semi-clickable" onClick={() => setShowLargeCard(!showLargeCard)}>
			<div className="card-header">
			<img className="img" alt="Startup Icon" src={projectIcon1} />
			<div className="card-header-info">
				<div className="card-tags">
				<div className="category-wrapper">
					<div className="category-item">{category ? category: "Unknown"}</div>
				</div>
				<div className="status-wrapper">
					<div className="status-item">{status ? status: "Unknown"}</div>
				</div>
				{editMode ? ( visibility === "private"? (<span id="visibility" className='draft'>Draft</span>):(<span id="visibility" className='public'>Public</span>)):(<span></span>)}
				</div>
				<div className="title">{title}</div>
				<div className="sub-heading">
				<div className="date">Posted: {date_posted}</div>
				</div>
			</div>
			</div>
			<div className="card-description">
			<p className="p">{description}</p>
			</div>
			<div className="card-footer">
			<div className="avatar">
				<img className="avatar-image" alt="No image" onClick={handleAuthorClick} src={finalAvatarURL} />
				<div className="div">
				<div className="author-info">
					<div className="text" onClick={handleAuthorClick}>{manager_display_name}</div>
					<div className="time">
					<div className="text-2">{userTitle}</div>
					</div>
					<div className="time">
					{city && province ? (<div className="text-2">{city}, {province}</div>): ("")}
					</div>
				</div>
				</div>
			</div>
			<div className="recruiting-section">
				<div className="drop-down-wrapper" onClick={handleDropdownClick}>
				<div className="drop-down">
					<div className="text-3">Recruiting For</div>
					<img className="small-icon" alt="Frame" src={arrowDown} />
				</div>
				</div>
				{showDropdown && (
				<div className={'recruiting-dropdown slideDownMenu animated-fast'}>
					<ul>
					{recruiting_roles.map((roleObj, index) => (
					<li key={index}>{roleObj.role_name}</li>
					))}
					</ul>
				</div>
				)}
			</div>
			{applied && (
				<div>
				{/* <img className="frame-5" alt="Frame" src={arrowDown} /> */}
				</div>
			)}
			</div>
		</div>
		</div>
	);
};

export default SmallProjectCard;

