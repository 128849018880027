import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import classes from './LargeModal.module.scss';
import Icon from 'icons/icon';

const LargeModal = (props) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        // Start the closing animation
        setIsClosing(true);
      
        // Set a timeout that matches the animation duration
        setTimeout(() => {
          props.setShowLargeCard(false); // Replace with your method to close the portal
        }, 100); // This should match the animation duration
    };      

    return ReactDOM.createPortal(
        <div className={`${classes.overlay}`}>
            <div className={isClosing ? `${classes.closingAnimation}` : `${classes.portalAnimation}`}>
            <button className={`${classes.closeButton}`} hidden={props.hideCloseBtn} onClick={() => handleClose()}><Icon color="gray" size={15} icon="cross"/></button>
                {props.children}
            </div>
        </div>,
        document.getElementById("modal-root")
    );

}

export default LargeModal;