import React, { useState} from 'react';
import { Layout } from 'layout';
import classes from './UserProfileVideoOverlayed.module.scss'
import ReactPlayer from 'react-player';
import MarkerPin01 from "@untitled-ui/icons-react/build/cjs/MarkerPin01";
import { UserProfileData, UserProfileVideo } from 'components';
import { LargeModal} from 'sub-components';

const UserProfileVideoOverlayed = (props) => {
    const [showLargeCard, setShowLargeCard] = useState(false);
    const [loading, setLoading] = useState(true);

	return (
        <div>
            <div className={classes.mobileVideoContainer}>
                <div className={classes.videoOverlay} onClick={setShowLargeCard}>
                    <div className={classes.profileInfo}>
                        <div className={classes.displayName}>{props.pitchMetadata.displayName}</div>
                        {props.pitchMetadata?.location &&
                            <div className={classes.locationSection}>
                                <MarkerPin01 style={{width: '15px', height: '15px'}}/>
                                <div className={classes.location}>{props.pitchMetadata.location}</div>
                            </div>
                        }
                    </div>
                    <div className={classes.roleInfo}>
                        <div className={classes.expertise}>
                            {props.pitchMetadata?.expertise?.length > 0 && props.pitchMetadata?.expertise?.map((item, index) => (
                                <div key={index} className={classes.expertiseItem}>
                                    {item.display_name}
                                </div>
                            ))}
                        </div>
                        <div className={classes.title}>{props.pitchMetadata.title}</div>
                    </div>
                    {props.pitchMetadata.goal ? (
                        <div className={classes.additionalInfo}> 
                            <div>I want to {props.pitchMetadata.goal}.</div>
                        </div>
                    ) : (
                        <div className={classes.additionalInfo}> 
                            <div>I need to be reminded to complete my profile.</div>
                        </div>
                    )}
                </div>
                <UserProfileVideo pitchVideo={props.pitchVideo} isPlaying={props.isPlaying}/>
            </div>
            {showLargeCard && (
                <LargeModal setShowLargeCard={setShowLargeCard}>
                    <UserProfileData userId={props.pitchMetadata.userId}/>
                </LargeModal>
            )}
        </div>
	);
};

export default UserProfileVideoOverlayed;
